import React from 'react'
import Search from '../components/search/search'
import { graphql } from 'gatsby'

export default function ProjectsPage({ data, ...props }) {
  const properties = data.allProperty.edges.map((e) => e.node)
  return (
    <Search
      {...props}
      data={data}
      properties={properties}
      internalTypes={['project']}
    />
  )
}

export const query = graphql`
  query {
    allProperty(
      filter: { internal_type: { eq: "project" }, is_preview: { ne: true } }
    ) {
      edges {
        node {
          ...PropertyCard
          properties {
            id
            attributes {
              price {
                published_price {
                  amount
                  currency
                }
              }
            }
          }
        }
      }
    }
  }
`
